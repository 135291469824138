import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Role } from 'src/app/shared/enum/role.enum';
import { User } from 'src/app/shared/interface/user.interface';
import { JWTService } from './jwt.service';
import { StorageService } from './storage.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private _storage: StorageService, private _userService: UserService, private _jwt: JWTService, private _router: Router) { }

  public setStatus(status: boolean) {
    this._storage.set('loginStatus', true)
  }

  public getLoginStatus() {
    return (this._storage.get('loginStatus')?.toLowerCase() === 'true');
  }

  public login(token: string, user: User) {
    this.setStatus(true);
    this._jwt.setToken(token);
    this._userService.setUser(user);
  }

  public logout(): void {
    this._storage.unset('loginStatus');
    this._storage.unset('user');
    this._storage.unset('token');
    this._router.navigate(['login']);
  }

  /**
   * Check if user role is within given roles
   * @param roles 
   * @returns 
   */
  roleIsAuthorized(roles: Role[]) {
    const userRole: Role = this._userService.getCurrentUser().role.id;
    
    if (userRole) {
        // check for role restrictions
        if (roles && roles.indexOf(userRole) === -1) {
            // if role is not authorized
            return false;
        }
        // if role is authorized or no role needed
        return true;
    }

    return false;
  }

}
