import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Settings } from 'src/app/settings/settings.service';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
@Injectable()

export class RESTService {

    private _headers: HttpHeaders;

    constructor(private _http: HttpClient, private _settings: Settings) {
        this._headers = this._settings.httpOptions.headers;
    }

    /**
     * Returns data from a _http get url
     * @param url 
     * @param data 
     */
    public get(url: any): Observable<any> {
        return this._http.get(this._settings.apiUrl + url, {headers: this._headers});
    }

    /**
     * POST data to a _http Post url
     * @param url 
     * @param data 
     */
    public post(url: string, data: any) {
        return this._http.post(this._settings.apiUrl + url, data, {headers: this._headers});
    }

    /**
     * PUT data to a _http Put url
     * @param url
     * @param data 
     */
    public put(url: string, data: any) {
        return this._http.put(this._settings.apiUrl + url, data, {headers: this._headers});
    }

    /**
     * DELETE data from a _http detele url
     * @param url
     * @param data 
     */
    public delete(url: string) {
        return this._http.delete(this._settings.apiUrl + url, {headers: this._headers});
    }

    public getPing() { // Check if API is working and responding
        return this.get('ping');
    }
}