import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from "@angular/router";
import { SnackbarStatusType } from "src/app/shared/enum/snackbarStatusType.enum";
import { SnackbarService } from "src/app/shared/services/snackbar.service";
import { SpinnerService } from "src/app/shared/services/spinner.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private _snackbarService: SnackbarService, 
    private _spinnerService: SpinnerService, 
    private _zone: NgZone,
    private _injector: Injector
  ) {}
  handleError(error: Error | HttpErrorResponse) {
    console.log('test')
    console.log(error)
    if (error instanceof HttpErrorResponse) {
      
      this._spinnerService.hideSpinner();
      if(error.status === 0) {
        this._zone.run(()=>{
          this._snackbarService.openSnackBar('Server offline! Please try again later.', 'OK', SnackbarStatusType.error);
        });
      } else {
        this._zone.run(()=>{
          if(error.error.error.message) {
            this._snackbarService.openSnackBar(error.error.error.message, 'OK', SnackbarStatusType.error);
          }
          else if (error.error.error.description) {
            this._snackbarService.openSnackBar(error.error.error.description, 'OK', SnackbarStatusType.error);
          }
          
        });
      }
    } else {
      // Client Error
      const router = this._injector.get(Router);
      console.log(error.stack ? error.stack.toString() : 'unknown error');
    }
  }
}