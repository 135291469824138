<mat-sidenav-container>
  <mat-nav-list class="sidebar" [ngClass]="{'collapsed': sidebarService.collapsed}">
    <span class="menu">
      <button mat-icon-button>
        <mat-icon matListIcon (click)="toggleSidebar()">menu</mat-icon>
      </button>
    </span>
    
    <ng-container *ngFor="let link of links; let i = index">
      <h3 matCardSubtitle class="menu-divider" *ngIf="roleIsAuthorized(link.roles) && link.isSeparator">{{link.title}}</h3>
      <mat-list-item *ngIf="roleIsAuthorized(link.roles) && !link.isSeparator" [routerLink]="link.url" routerLinkActive="active-link" matTooltipClass="sidebar-tooltip" [matTooltipDisabled]="!sidebarService.collapsed" [matTooltip]="link.title" matTooltipPosition="after">
        <mat-icon matListIcon position="start">{{link.icon}}</mat-icon>
        <span matLine>{{ link.title }}</span>
      </mat-list-item>
    </ng-container>
  </mat-nav-list>
</mat-sidenav-container>