import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  { 
    path: '', 
    redirectTo: 'home',
    pathMatch: 'full',
    data: { animate: false }
  },
  { path: 'login', loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule) },
  { path: 'home', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
  { path: 'user-management', loadChildren: () => import('./modules/user-management/user-management.module').then(m => m.UsermanagementModule) },
  { path: 'edit/user', loadChildren: () => import('./modules/user-edit/user-edit.module').then(m => m.UsereditModule) },
  { path: 'user-profile/edit', loadChildren: () => import('./modules/user-profile-edit/user-profile-edit.module').then(m => m.UserProfileEditModule) },
  { path: 'right-management', loadChildren: () => import('./modules/right-management/right-management.module').then(m => m.RightManagementModule) },
  { path: 'role-management', loadChildren: () => import('./modules/user-role-management/user-role-management.module').then(m => m.RoleManagementModule) },
  { path: 'edit/user/role', loadChildren: () => import('./modules/user-role-edit/user-role-edit.module').then(m => m.UserRoleEditModule) },
  { path: 'right/details', loadChildren: () => import('./modules/right-details/right-details.module').then(m => m.RightDetailsModule) },
  {
		path: '**',
    redirectTo: 'home',
    data: { animate: false }
	}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }