import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Event, NavigationStart, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})

export class ToolbarComponent implements OnInit {

  public username: string;

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _userService: UserService
    ) {
      this.username = this._userService.getCurrentUser().username;
  }

  ngOnInit() { }

  logout() {
    this._authService.logout();
    this._router.navigate(['login']);
  }

}
