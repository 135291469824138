import { Injectable } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  mode: ProgressSpinnerMode = 'indeterminate';
  color: ThemePalette = 'accent';
  active: boolean = false;
  
  constructor() { }

  public showSpinner() {
    this.active = true;
  }

  public hideSpinner() {
    this.active = false;
  }

  public toggleSpinner() {
    this.active = !this.active;
  }

}
