import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../components/dialog/dialog.component';

@Injectable({
  providedIn: 'root'
})

export class DialogService {

  public ok: boolean = false;
  public cancel: boolean = false;

  constructor(public dialog: MatDialog) {}

  openDialog(headline: string, msg: string) {
    this.setButtonDefaults();
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {headline: headline, message: msg},
    });
    return dialogRef;
  }

  setButtonDefaults() {
    this.ok = false;
    this.cancel = false;
  }
}