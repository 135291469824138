import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { RESTService } from './rest.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root'
})

export class JWTService {

    private refreshTokenTimeout: any;
    private checkRefreshExpirationTimeout: any;
    private expires: Date;
    private timeout: number;
    private timeBeforeLogout: number = 600 * 1000; // in seconds

    constructor(
        private _rest: RESTService, 
        private _storage: StorageService, 
        private _router: Router,
        private _dialog: DialogService
    ) { }

    /**
     * Set the token to the storage and
     * start refresh token timout
     * @param token 
     */
    setToken(token: string) {
        this._storage.set('token', token);
        this.startRefreshTokenTimer();
    }

    /**
     * Get token or return empty string
     * @returns string
     */
    private getToken() {
        const token = this._storage.get('token');
        if(token !== null) {
            return token;
        } else {
            return '';
        }
    }

    /**
     * Logout process and clear storage
     * @returns void
     */
    private logout() {
        this._storage.unset('loginStatus');
        this._storage.unset('user');
        this._storage.unset('token');
        this._router.navigate(['login']);
    }

    /**
     * Check if refresh expiration timer is expired
     * @returns void
     */
    public checkRefreshExpiration() {
        console.log('checkRefreshExpiration');
       
        let jwtToken = JSON.parse(atob(this.getToken()?.split('.')[1]));
        let expires = new Date(jwtToken.exp * 1000); // get expired date time value
        if(expires.getTime() < Date.now()) {
            this.stopRefreshTokenTimer();
            this.stopRefreshExpirationTimer();
            this._dialog.dialog.closeAll();
            this.logout();
        }
        console.log(expires);
        return;
    }

    /**
     * Refresh JWT token or logout
     * @returns void
     */
    private refreshToken() {
        // console.log('Refreshing token');
        this.checkRefreshExpirationTimeout = setTimeout(() => this.checkRefreshExpiration(), this.timeBeforeLogout);
        const dialog = this._dialog.openDialog('Achtung', 'Ihre Loginsitzung läuft ab. Möchten Sie eingeloggt bleiben?');
        return dialog.afterClosed().subscribe(result => {
            // console.log(this._dialog.ok);
            // console.log(this._dialog.cancel);
            if(this._dialog.cancel) {
                this.stopRefreshTokenTimer();
                this.stopRefreshExpirationTimer();
                this.logout();
            }
            if(this._dialog.ok && this.expires.getTime() > Date.now()) {
                return this._rest.get('refreshToken')
                    .pipe(map(async (result) => {
                        this.stopRefreshExpirationTimer();
                        this.setToken(result.data.jwt);
                    })).subscribe();
            }
            return;
        })
    }

    /**
     * Start refreshTokenTimeout and check if a JWT token is given
     * @returns void
     */
    private startRefreshTokenTimer() {
        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(this.getToken()?.split('.')[1]));

        if(!jwtToken) {
            this.stopRefreshTokenTimer();
            this.logout();
        }

        // set a timeout to refresh the token 10 minutes before it expires
        this.expires = new Date(jwtToken.exp * 1000); // get expired date time value
        //console.log('expires: ' + this.expires);
        this.timeout = this.expires.getTime() - Date.now() - (this.timeBeforeLogout); // add 10 minutes
        //console.log('timeout: ' + this.timeout);
        this.refreshTokenTimeout = setTimeout(() => this.refreshToken(), this.timeout);
    }

    /**
     * Stop the refreshTokenTimer
     * @returns void
     */
    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }

    /**
     * Stop the stopRefreshExpirationTimer
     * @returns void
     */
    private stopRefreshExpirationTimer() {
        clearTimeout(this.checkRefreshExpirationTimeout);
    }
}