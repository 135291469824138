import { Injectable } from '@angular/core';
import { 
  HttpEvent, HttpRequest, HttpHandler, 
  HttpInterceptor, HttpErrorResponse 
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { AuthService } from '../services/auth.service';
import { JWTService } from '../services/jwt.service';
import { environment } from 'src/environments/environment';
import { SnackbarStatusType } from 'src/app/shared/enum/snackbarStatusType.enum';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
    constructor(private _authService: AuthService, private _jwtService: JWTService, private _snackbarService: SnackbarService) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request).pipe(
        catchError((response: HttpErrorResponse) => {
          console.log(response);
          if ([403, 401].includes(response.status) && this._authService.getLoginStatus()) {
            // force logout if 403 response returned from api
            this._jwtService.checkRefreshExpiration();
            //this._authService.logout();
          } else if ([500].includes(response.status) && this._authService.getLoginStatus() && !environment.production) {
            if(response.error.error.message) {
              this._snackbarService.openSnackBar(response.error.error.message, 'OK', SnackbarStatusType.error);
            }
            else if (response.error.error.description) {
              this._snackbarService.openSnackBar(response.error.error.description, 'OK', SnackbarStatusType.error);
            }
          } else {
            this._snackbarService.openSnackBar('Unknown error.', 'OK', SnackbarStatusType.error);
          }
          return throwError(() => {return response});
        })
      );   
    }
}