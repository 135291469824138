import { Injectable } from '@angular/core';
import { StorageService } from 'src/app/core/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  public collapsed: boolean = false;

  constructor(private _storage: StorageService) {
    let rememberCollapse = this._storage.get('sidebarCollapsed');
    if(rememberCollapse) {
      this.collapsed = (rememberCollapse.toLowerCase() === 'true');
    }
  }

  toggleSidebar() {
    this.collapsed = !this.collapsed;
    this._storage.set('sidebarCollapsed', this.collapsed);
  }

  getStatus() {
    return this.collapsed;
  }

}