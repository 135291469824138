import { Injectable } from '@angular/core';
import { User } from 'src/app/shared/interface/user.interface';
import { RESTService } from './rest.service';
import { StorageService } from './storage.service';
import { map, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})

export class UserService {

  constructor(private _storage: StorageService, private _rest: RESTService) { }

  getAll() {
    return this._rest.get('users');
  }

  setUser(user: User) {
    this._storage.set('user', user);
  }

  getCurrentUser(): User {
    return JSON.parse(this._storage.get('user'));
  }

  getUserById(id: number): Observable<User> {
    return this._rest.get('users/' + id,).pipe(map((result) => {
      let user = result.data;
      return user;
    }));
  }

  setToken(token: string) {
    this._storage.set('token', token);
  }

  getToken() {
    return this._storage.get('token');
  }

  setStatus(userId: number, statusId: number) {
    return this._rest.put('users/status', {id: userId, statusId: statusId});
  }

  getStatus() {
    return this.getCurrentUser().status;
  }

  updateUser(user: User) {
    return this._rest.put('users', {user: user});
  }

  updateUserProfile(user: User) {
    return this._rest.put('users/profile', {user: user});
  }

  delete(userId: number) {
    return this._rest.delete('users/' + userId);
  }

}
