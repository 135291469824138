import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { NavigationModule } from './navigation/navigation.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { Settings } from '../settings/settings.service';
import { RESTService } from './services/rest.service';
import { MaterialuiModule } from './materialui/materialui.module';
import { ContentComponent } from './components/content/content.component';
import { GlobalErrorHandler } from './interceptor/globalErrorHandler';
import { ServerErrorInterceptor } from './interceptor/serverErrorInterceptor';
import { JwtInterceptor } from './interceptor/jwtInterceptor';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SpinnerComponent } from '../shared/components/spinner/spinner.component';

@NgModule({
    declarations: [
      SidebarComponent,
      ContentComponent,
      ToolbarComponent,
      SpinnerComponent
    ],
    imports: [
        CommonModule, 
        RouterModule, 
        HttpClientModule,
        NavigationModule,
        MaterialuiModule
    ],
    exports: [
        // Modules
        CommonModule,
        RouterModule,
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        NavigationModule,
        MaterialuiModule,
        // Components
        SidebarComponent,
        ContentComponent,
        ToolbarComponent,
        SpinnerComponent
    ],
    providers: [
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
        
        Settings,
        RESTService
    ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(`${parentModule.constructor.name} has already been loaded.`);
    }
  }
}