import { trigger, transition, style, query, group, animateChild, animate, stagger } from '@angular/animations';

export const fadeInOut = trigger(
  'fadeInOut', 
  [
    transition(
      ':enter',
      [
        style({ opacity: 0 }),
        animate('.5s ease',
                style({ opacity: 1 }))
      ]
    ),
    transition(
      ':leave',
      [
        style({ opacity: 1 }),
        animate('.5s ease',
                style({ opacity: 0 }))
      ]
    )
  ]
)

export const fadeInAnimation =
trigger('routeAnimations', [
  transition('* <=> *', [
    style({ position: 'fixed', width: '100%' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%'
      })
    ], { optional: true }),
    query(':enter', [
      style({ opacity: 0 })
    ], { optional: true }),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(':leave', [
        animate('300ms ease-in-out', style({ left: '0%', opacity: 0 }))
      ], { optional: true }),
      query(':enter', [
        animate('300ms ease-in-out', style({ left: '0%', opacity: 1 }))
      ], { optional: true })
    ]),
    query(':enter', animateChild(), { optional: true }),
  ])
]);

export const slideInAnimation2 =
  trigger('routeAnimations', [
    transition('* <=> *', [
      style({ position: 'fixed', width: '100%' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ], { optional: true }),
      query(':enter', [
        style({ left: '100%'})
      ], { optional: true }),
      query(':leave', animateChild(), { optional: true }),
      group([
        query(':leave', [
          animate('300ms ease-in-out', style({ left: '-100%'}))
        ], { optional: true }),
        query(':enter', [
          animate('300ms ease-in-out', style({ left: '0%'}))
        ], { optional: true })
      ]),
      query(':enter', animateChild(), { optional: true }),
    ])
]);