import { MediaMatcher } from '@angular/cdk/layout';
import { ViewChild, Component, OnInit, ElementRef } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserService } from 'src/app/core/services/user.service';
import { Role } from 'src/app/shared/enum/role.enum';
import { SidebarService } from 'src/app/shared/services/sidebar.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  links = [
    {
      title: 'Navigation',
      isSeparator: true,
      roles: [Role.Admin, Role.Moderator, Role.User]
    },
    {
      title: 'Dashboard',
      url: 'home',
      icon: 'dashboard',
      roles: [Role.Admin, Role.Moderator, Role.User]
    },
    {
      title: 'Verwaltung',
      isSeparator: true,
      roles: [Role.Admin, Role.Moderator]
    },
    {
      title: 'Userverwaltung',
      url: 'user-management',
      icon: 'account_circle',
      roles: [Role.Admin, Role.Moderator]
    },
    {
      title: 'Rechteverwaltung',
      url: 'right-management',
      icon: 'https',
      roles: [Role.Admin, Role.Moderator]
    },
    {
      title: 'Rollenverwaltung',
      url: 'role-management',
      icon: 'groups_2',
      roles: [Role.Admin, Role.Moderator]
    }
  ]

  constructor(
    public sidebarService: SidebarService,
    private _userService: UserService,
    private _authService: AuthService
  ) {
    
  }

  ngOnDestroy(): void {

  }

  ngOnInit() {
    
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

  }

  roleIsAuthorized(roles: Role[]) {
    return this._authService.roleIsAuthorized(roles)
  }

  toggleSidebar() {
    this.sidebarService.toggleSidebar();
  }

}