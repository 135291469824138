import { Component, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Event, NavigationStart, Route, Router, RouterOutlet } from '@angular/router';
import { AuthService } from './core/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {

  title = 'app-sekelton';
  loginStatus: boolean = false;
  currentRoute: string = '';

  constructor(
    private overlayContainer: OverlayContainer,
    private _router: Router,
    private _authService: AuthService
  ) {
    this.currentRoute = "";
    this._router.events.subscribe((event: Event) => {
        if (event instanceof NavigationStart) {
            // Show progress spinner or progress bar
            this.loginStatus = this._authService.getLoginStatus();
            console.log('Route change detected');
        }
    })
  }

  ngOnInit() {

  }
  
  ngOnDestroy() {

  }
}