import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class Settings {
    public apiUrl: string = environment.apiUrl;
    public apiKey: string = environment.apiKey;
    public httpOptions = {
        headers: new HttpHeaders({ 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: '',
            //'userid':'1'
        }),
    };
}