<mat-toolbar id="toolbar"  >
  <span class="logo-group"></span>
  <span class="spacer"></span>
  <button mat-button position="end" [matMenuTriggerFor]="usermenu" class="usermenu">
    Welcome, {{username}}
    <mat-icon>expand_more_black</mat-icon>
  </button>
  <mat-menu #usermenu="matMenu">
    <button mat-menu-item position="end" routerLink="/user-profile/edit">
      <mat-icon>account_circle</mat-icon>
      <span matLine>Usereinstellungen</span>
    </button>
    <button mat-menu-item class="example-icon" aria-label="Example icon-button with share icon" (click)="logout()">
      <mat-icon>logout</mat-icon>
      <span matLine>Logout</span>
    </button>
  </mat-menu>
</mat-toolbar>