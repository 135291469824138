import { Component, Input, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { fadeInAnimation, slideInAnimation2 } from 'src/app/core/services/animation.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { SidebarService } from '../../../shared/services/sidebar.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  animations: [fadeInAnimation]
})

export class ContentComponent implements OnInit {

  @Input('loginStatus') public loginStatus: any;

  constructor(private _storage: StorageService, public sidebarService: SidebarService) { }

  ngOnInit() { }

  prepareRoute(outlet: RouterOutlet) {
    if(outlet.activatedRouteData['animate']) {
      return outlet && outlet.activatedRouteData
    }
    return false;
  }

}
