import { Injectable } from '@angular/core';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarStatusType } from '../enum/snackbarStatusType.enum';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  private horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  private verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  protected cssClass: string = 'success';

  constructor(private _snackBar: MatSnackBar) {}

  openSnackBar(message: string, action: string = '', status: SnackbarStatusType = SnackbarStatusType.success) {
    this._snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: status
    });
  }

  public setClass(cssClass: string) {
    this.cssClass = cssClass;
  }

  public getClass(): string {
    return this.cssClass;
  }

}