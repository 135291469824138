import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})

export class DialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private _dialog: DialogService) {}

  clickOk() {
    this._dialog.ok = true;
  }

  clickCancel() {
    this._dialog.cancel = true;
  }
}