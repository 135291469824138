import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class StorageService {

    constructor() { }

    /**
     * Get a key/value pair from localStorage
     */
    public get(string: string): any {
        return localStorage.getItem(string);
    }

    /**
     * Set a key/value pair to localStorage
     */
    public set(key: string, value: any) {
        console.log("Write: " + value)
        localStorage.setItem(key, JSON.stringify(value));
    }

    /**
     * Unset a key/value pair to localStorage
     */
    public unset(key: string) {
        localStorage.removeItem(key);
    }

    /**
     * Clear localStorage
     */
    clear() {
        localStorage.clear();
    }

}